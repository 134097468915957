import { createRef, forwardRef, useState, useCallback /*, useEffect */ } from "react";
// import { Link } from "react-router-dom";
import classNames from "classnames"

import Main from "../components/Main";
import Section from "../components/Section";
import Container from "../components/Container";
import Typography from "../components/Typography";

import Modal from "../components/Modal"
import ContactForm from "../components/Forms/Contact"

// import { scrollTo } from "../hooks/scroll"
import { useBreakpoint } from "../hooks/breakpoint";

// import MamboLogo from "../assets/mambo.png";
// import DigitaliaLogo from "../assets/digitalia.png";
import HackSpaceLogo from "../assets/hackspace.png"
import HackSpaceBee from "../assets/hackspace-bee.png"

import { ReactComponent as PageBg } from "../assets/home-page-bg.svg"
import Section1Decoration from "../assets/home-section1-decoration.png"
import { ReactComponent as Section2Bg } from "../assets/home-section2-bg.svg"
// import Section2Contact from "../assets/home-section2-data-analytics.png"
// import Section2ProjectDigital from "../assets/home-section2-project-digital.png"
// import Section2GrowthHacker from "../assets/home-section2-growth-hacker.png"
import { ReactComponent as CoursesDecoration1 } from "../assets/home-courses-decoration1.svg"
import { ReactComponent as CoursesDecoration2 } from "../assets/home-courses-decoration2.svg"
import Section3Decoration1 from "../assets/home-section3-decoration1.png"
import Section3Decoration2 from "../assets/home-section3-decoration2.png"
import Section4Decoration from "../assets/partners-section4-decoration.png"
// import { ReactComponent as Section4FormBg } from "../assets/home-section4-form-bg.svg"
import AboutUsBg from "../assets/home-aboutus-bg.png"
import { ReactComponent as AboutUsDecoration } from "../assets/home-aboutus-decoration.svg"

// import { ReactComponent as ArrowRightIcon } from "../assets/arrow-right.svg"
// import { ReactComponent as BookmarkIcon } from "../assets/bookmark.svg"
import { ReactComponent as PresentationIcon } from "../assets/presentation.svg"
// import { ReactComponent as Check } from "../assets/check.svg"
import { ReactComponent as ChevronRightIcon } from "../assets/chevron-right-blue.svg"
// import { ReactComponent as CodeIcon } from "../assets/code.svg"
// import { ReactComponent as ShieldIcon } from "../assets/shield.svg"
// import { ReactComponent as SmileIcon } from "../assets/smile.svg"
// import { ReactComponent as WhatsappLogo } from "../assets/whatsapp.svg"
import { ReactComponent as Download } from "../assets/download-blue.svg"

import { ReactComponent as MarketingIcon } from "../assets/home-courses-marketing-icon.svg"
import { ReactComponent as DataScienceIcon } from "../assets/home-courses-datascience-icon.svg"


const Intro = () => {
  const Decoration = () => {
    const breakpoint = useBreakpoint()

    return <div
      className="md:col-span-5 flex flex-col justify-center items-center">
      <img
        src={Section1Decoration}
        alt="home"
        className="block ml-auto w-full"
        style={{
          maxWidth: breakpoint?.mdAndUp ? "480px" : "360px",
        }} />
    </div>
  }

  const Text = () => {
    const breakpoint = useBreakpoint()

    return <div
      className="md:col-span-7 flex flex-col justify-center items-center">
      <div
        className="flex flex-col md:flex-row w-full">
        <div
          className="flex-none">
          <img
            src={HackSpaceBee}
            alt="HackSpace"
            className="w-40 md:w-32 lg:w-40 h-auto mb-4 md:mr-8" />
        </div>
        <div
          className="flex-grow">
          <Typography
            monospaced
            size={breakpoint?.lgAndUp ? "nine" : (breakpoint?.mdAndUp ? "six" : "seven")}
            className="font-light text-right sm:text-left text-hs-blue-600">
            Asociación
          </Typography>
          <Typography
            monospaced
            size={breakpoint?.lgAndUp ? "nine" : (breakpoint?.mdAndUp ? "six" : "seven")}
            className="font-light text-right sm:text-left text-hs-blue-600">
            Hack Space
          </Typography>
          <Typography
            monospaced
            size={breakpoint?.lgAndUp ? "nine" : (breakpoint?.mdAndUp ? "six" : "seven")}
            className="font-light text-right sm:text-left text-hs-blue-600 mb-4">
            Perú
          </Typography>
          <Typography
            size={breakpoint?.lgAndUp ? "five" : "four"}
            className="lg:w-3/4 text-hs-yellow-300 font-bold">
            + <span className="text-hs-blue-300">Generamos</span> Comunidad
          </Typography>
          <Typography
            size={breakpoint?.lgAndUp ? "five" : "four"}
            className="lg:w-3/4 text-hs-yellow-300 font-bold">
            + Difundimos <span className="text-hs-blue-300">Tecnología</span>
          </Typography>
        </div>
      </div>
    </div>
  }

  const Content = () => {
    const breakpoint = useBreakpoint()

    return <div
      className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-12 gap-12 sm:gap-8">
      {
        breakpoint?.smAndUp
          ? <>
            <Text />
            <Decoration />
          </>
          : <>
            <Decoration />
            <Text />
          </>
      }
    </div>
  }

  return <Section
    className="py-8 md:py-16">
    <Container>
      <Content />
    </Container>
  </Section>
}

const Navigation = () => {
  const NavbarItem = ({ children, ...attrs }) => {
    attrs.className = classNames(
      attrs.className,
      [
        "h-20",
        "flex",
        "flex-col",
        "justify-center",
        "py-3"
      ]
    )

    return <div {...attrs}>
      {children}
    </div>
  }

  const Navbar = () => {
    const [isModalOpen, setIsModalOpen] = useState(false)

    const onModalClose = useCallback(
      () => {
        setIsModalOpen(false)
      },
      []
    )

    return <>
      <Modal
        open={isModalOpen}
        onClose={onModalClose}>
        <div
          className="w-screen max-w-screen h-screen max-h-screen p-4 flex items-center justify-center"
          onClose={onModalClose}>
          <div
            className="bg-white p-4 md:py-8 md:px-16 w-full rounded-3xl max-h-full overflow-auto pointer-events-auto"
            style={{
              maxWidth: '480px'
            }}
            onClick={(e) => e.stopPropagation()}>
            <ContactForm formId="contact-form-on-body-navbar" />
          </div>
        </div>
      </Modal>
      <div
        className="flex flex-row px-16 lg:px-24">
        <NavbarItem
          className="mr-4">
          <Typography
            size="two"
            className="text-center">
            Comunidad
          </Typography>
        </NavbarItem>
        <NavbarItem
          className="mx-4">
          <Typography
            size="two"
            className="text-center">
            Cursos
          </Typography>
        </NavbarItem>
        <NavbarItem
          className="mx-4">
          <Typography
            size="two"
            className="text-center">
            Outsourcing
          </Typography>
        </NavbarItem>
        <NavbarItem
          className="mx-4">
          <Typography
            size="two"
            className="text-center">
            Nosotros
          </Typography>
        </NavbarItem>
        <div
          className="flex-grow" />
        <NavbarItem
          className="ml-4">
          <div
            className="bg-hs-yellow-300 p-3 rounded-3xyl4 roundex-8xyl4cursor-pointer"
            onClick={() => { setIsModalOpen(true); }}>
            <Typography
              size="three"
              className="text-center">
              ¡Contáctanos!
            </Typography>
          </div>
        </NavbarItem>
      </div>
    </>
  }

  const Content = () => {
    return <div
      className="bg-white h-20 shadow-lg relative z-10">
      <Navbar />
    </div>
  }

  return <Section
    className="hidden md:block">
    <Container>
      <Content />
    </Container>
  </Section>
}

const Community = forwardRef(
  ({ breakpoint }, ref) => {
    const Background = ({ breakpoint }) => {
      return <>
        {
          breakpoint?.mdAndUp
            ? <Section2Bg
              preserveAspectRatio="none"
              className="block absolute bottom-0 left-0 h-full w-full"
              style={{
                height: "calc(100% + 256px)"
              }} />
            : null
        }
      </>
    }

    const Content = ({ breakpoint }) => {
      return <div
        className="grid grid-cols-1 md:grid-cols-12 gap-8 md:gap-16">
        <div
          className="md:col-span-12 flex flex-col md:flex-row">
          <div
            className="flex-grow mb-4 md:mb-0 md:mr-8">
            <Typography
              size={breakpoint?.lgAndUp ? "eight" : (breakpoint?.mdAndUp ? "seven" : "six")}
              monospaced
              className="font-light text-hs-blue-600 relative">
              <span className="mr-4">Potencia tu talento</span>
              <span className="text-hs-red-300 font-black">{">"}_</span>
            </Typography>
            <Typography
              size={breakpoint?.lgAndUp ? "six" : (breakpoint?.mdAndUp ? "seven" : "six")}
              monospaced
              className="font-light text-hs-red-300">
              únete a nuestra comunidad
            </Typography>
          </div>
          <div
            className="flex-none">
            <Typography
              tag="a"
              size="three"
              href="#download-manual"
              className="text-center inline-block bg-hs-yellow-300 p-3 rounded-3xl cursor-pointer">
              <Download
                className="w-4 h-4 ml-2" />
              Descárgate el manual
            </Typography>
          </div>
        </div>

        <div
          className="flex flex-col justify-center md:col-span-6">
          <img
            src={Section3Decoration1}
            alt="Postula al programa"
            className="w-full h-auto" />
        </div>
        <div
          className="flex flex-col justify-between md:col-span-6">
          <div
            className="mb-4">
            <Typography
              size={breakpoint?.mdAndUp ? "seven" : "five"}
              className="text-hs-blue-600 font-bold mb-4">
              Postula al Programa
            </Typography>
            <Typography
              size="three"
              className="text-hs-blue-600 mb-4">
              Involúcrate por 3 meses
            </Typography>
            <div
              className="h-2 w-20 bg-yellow-300 mb-4" />
            <Typography
              monospaced
              size="four"
              className="text-hs-blue-600">
              Mejora tus habilidades en Data Science<br className="hidden lg:inline" />
              y Desarrollo en corto tiempo.
            </Typography>
          </div>
          <div>
            <Typography
              size="three"
              className="block md:inline-block text-hs-blue-600 bg-hs-yellow-300  px-8 py-4 rounded-3xl cursor-pointer text-center mb-4">
              Inscripciones cerradas
            </Typography>
            <Typography
              size="one"
              className="text-hs-blue-600">
              Inscripciones hasta el 3 de setiembre
            </Typography>
          </div>
        </div>
        {
          breakpoint?.mdAndUp
            ? null
            : <div
              className="flex flex-col justify-center md:col-span-6">
              <img
                src={Section3Decoration2}
                alt="Se un facilitador"
                className="w-full h-auto" />
            </div>
        }
        <div
          className="flex flex-col justify-between md:col-span-6">
          <div
            className="mb-4">
            <Typography
              size={breakpoint?.mdAndUp ? "seven" : "five"}
              className="text-hs-blue-600 font-bold mb-4">
              Sé un Facilitador
            </Typography>
            <Typography
              size="three"
              className="text-hs-blue-600 mb-4">
              Dirige una Tribu
            </Typography>
            <div
              className="h-2 w-20 bg-yellow-300 mb-4" />
            <Typography
              monospaced
              size="four"
              className="text-hs-blue-600 mb-4">
              Inspira y guía a jóvenes talento.<br />
              Involúcrate 2 horas a la semana.
            </Typography>
          </div>
          <div>
            <Typography
              size="three"
              className="block md:inline-block text-hs-blue-600 bg-hs-yellow-300  px-8 py-4 rounded-3xl cursor-pointer text-center mb-4">
              Inscripciones cerradas
            </Typography>
            <Typography
              size="one"
              className="text-hs-blue-600">
              Inscripciones hasta el 28 de agosto
            </Typography>
          </div>
        </div>
        {
          breakpoint?.mdAndUp
            ? <div
              className="flex flex-col justify-center md:col-span-6">
              <img
                src={Section3Decoration2}
                alt="Se un facilitador"
                className="w-full h-auto" />
            </div>
            : null
        }
      </div>
    }

    return <Section
      ref={ref}
      className="pt-12 pb-12 md:pt-16 md:pb-20 bg-gray-100 md:bg-transparent">
      <Background breakpoint={breakpoint} />
      <Container>
        <Content breakpoint={breakpoint} />
      </Container>
    </Section>
  }
)

const Courses = () => {
  const Decoration1 = () => {
    const breakpoint = useBreakpoint()

    return <>
      {
        breakpoint?.mdAndUp
          ? <CoursesDecoration1
            className="absolute left-0 bottom-0 z-0 transform -translate-x-1/3 translate-y-1/4 w-1/3 h-auto" />
          : null
      }
    </>
  }

  const Decoration2 = () => {
    const breakpoint = useBreakpoint()

    return <>
      {
        breakpoint?.lgAndUp
          ? <CoursesDecoration2
            className="absolute right-0 top-1/2 z-0 transform translate-x-1/4 -translate-y-1/2 w-1/2 h-auto" />
          : null
      }
    </>
  }

  const Card = ({ children }) => {
    return <div
      className="relative border-2 rounded-2xl border-hs-yellow-300 py-12 md:py-24 px-6 h-full lg:h-auto bg-white">
      {children}
    </div>
  }

  const MarketingCard = () => {
    const breakpoint = useBreakpoint()

    return <Card>
      <div
        className="flex flex-col justify-center items-center">
        <div
          className="bg-hs-yellow-300 w-20 h-20 p-4 rounded-xl absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
          <MarketingIcon
            className="block w-12 h-12" />
        </div>
        <Typography
          size={breakpoint?.lgAndUp ? 'eight' : breakpoint?.mdAndUp ? 'seven' : 'six'}
          className="font-bold text-center text-hs-blue-600 mb-8">
          Analytics para Marketing y CRM
        </Typography>
        <Typography
          size="four"
          className="text-center text-hs-blue-600 mb-24">
          Aplicacines para profesionales del sector, desde cero.
        </Typography>
        <Typography
          tag="a"
          size="three"
          href="#marketing-info"
          className="text-center inline-block bg-hs-yellow-300 py-4 px-8 rounded-3xl cursor-pointer">
          Más informacion
          <ChevronRightIcon
            className="w-4 h-4 ml-4" />
        </Typography>
      </div>
    </Card>
  }

  const DataScienceCard = () => {
    const breakpoint = useBreakpoint()

    return <Card>
      <div
        className="flex flex-col justify-center items-center">
        <div
          className="bg-hs-yellow-300 w-20 h-20 p-4 rounded-xl absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
          <DataScienceIcon
            className="block w-12 h-12" />
        </div>
        <Typography
          size={breakpoint?.lgAndUp ? 'eight' : breakpoint?.mdAndUp ? 'seven' : 'six'}
          className="font-bold text-center text-hs-blue-600 mb-8">
          Introducción a Data Science
        </Typography>
        <Typography
          size="four"
          className="text-center text-hs-blue-600 mb-24">
          Aprende a aplicar Machine Learning, inteligencia artificial y analitica en los negocios.
        </Typography>
        <Typography
          tag="a"
          size="three"
          href="#datascience-info"
          className="text-center inline-block bg-hs-yellow-300 py-4 px-8 rounded-3xl cursor-pointer">
          Más informacion
          <ChevronRightIcon
            className="w-4 h-4 ml-4" />
        </Typography>
      </div>
    </Card>
  }

  const Content = () => {
    const breakpoint = useBreakpoint()

    return <div className="grid grid-cols-1 md:grid-cols-12 gap-8 w-full relative z-10">
      <div
        className="md:col-span-12">
        <img
          src={HackSpaceLogo}
          alt="HackSpace"
          className="block mx-auto md:ml-0" />
      </div>
      <div
        className="md:col-span-12 lg:col-span-6 xl:col-span-8">
        <Typography
          size="four"
          className="font-light text-hs-blue-600 text-center md:text-left">
          Ofertas
        </Typography>
        <Typography
          size={breakpoint?.lgAndUp ? 'eight' : breakpoint?.mdAndUp ? 'seven' : 'six'}
          className="text-hs-blue-600 font-bold md:font-black text-center md:text-left">
          Cursos para acelerar tu aprendizaje
        </Typography>
      </div>
      <div
        className="pt-10 md:col-span-6 lg:col-span-6 xl:col-span-4 lg:row-span-2">
        <MarketingCard />
      </div>
      <div
        className="pt-10 md:col-span-6 lg:col-span-6 xl:col-span-4 xl:col-start-5">
        <DataScienceCard />
      </div>
    </div>
  }

  return <Section
    className="py-16 md:py-24">
    <Container>
      <Decoration1 />
      <Decoration2 />
      <Content />
    </Container>
  </Section>
}

const Outsourcing = forwardRef(
  ({ breakpoint }, ref) => {
    const Card = ({ children }) => {
      return <div
        className="relative border-2 rounded-2xl border-hs-yellow-300 py-12 md:py-24 px-6 h-full lg:h-auto bg-white">
        {children}
      </div>
    }

    const DataScienceCard = () => {
      return <Card>
        <div
          className="flex flex-col justify-center items-center">
          <div
            className="bg-hs-yellow-300 w-20 h-20 p-4 rounded-xl mb-4">
            <PresentationIcon
              className="block w-12 h-12" />
          </div>
          <Typography
            size="three"
            className="text-center text-hs-blue-600 mb-4">
            Outsourcing
          </Typography>
          <Typography
            monospaced
            size={breakpoint?.mdAndUp ? 'seven' : 'six'}
            className="text-center text-hs-blue-600 mb-4">
            Data Science
          </Typography>
          <Typography
            size="three"
            className="text-center text-hs-blue-600 mb-8">
            Innovación para empresas
          </Typography>
          <Typography
            tag="a"
            size="three"
            href="#datascience-info"
            className="text-center inline-block bg-hs-yellow-300 py-4 px-8 rounded-3xl cursor-pointer">
            Más informacion
            <ChevronRightIcon
              className="w-4 h-4 ml-4" />
          </Typography>
        </div>
      </Card>
    }

    const RecruitmentCard = () => {
      return <Card>
        <div
          className="flex flex-col justify-center items-center">
          <div
            className="bg-hs-yellow-300 w-20 h-20 p-4 rounded-xl mb-4">
            <PresentationIcon
              className="block w-12 h-12" />
          </div>
          <Typography
            size="three"
            className="text-center text-hs-blue-600 mb-4">
            Outsourcing
          </Typography>
          <Typography
            monospaced
            size={breakpoint?.mdAndUp ? 'seven' : 'six'}
            className="text-center text-hs-blue-600 mb-4">
            Reclutamiento
          </Typography>
          <Typography
            size="three"
            className="text-center text-hs-blue-600 mb-8">
            Encuentra al mejor talento
          </Typography>
          <Typography
            tag="a"
            size="three"
            href="#recritment-info"
            className="text-center inline-block bg-hs-yellow-300 py-4 px-8 rounded-3xl cursor-pointer">
            Más informacion
            <ChevronRightIcon
              className="w-4 h-4 ml-4" />
          </Typography>
        </div>
      </Card>
    }

    const Content = ({ breakpoint }) => {
      return <div className="grid grid-cols-1 md:grid-cols-12 gap-8 w-full relative z-10">
        <div
          className="md:col-span-12">
          <Typography
            size="four"
            className="font-light text-hs-blue-600 text-center">
            Servicios de Outsourcing
          </Typography>
          <Typography
            size={breakpoint?.mdAndUp ? 'seven' : 'six'}
            className="text-hs-blue-600 font-bold md:font-black text-center">
            Tambien ofrecemos
          </Typography>
        </div>
        <div
          className="md:col-span-6 lg:col-span-6 xl:col-span-4 xl:col-start-3">
          <DataScienceCard />
        </div>
        <div
          className="md:col-span-6 lg:col-span-6 xl:col-span-4">
          <RecruitmentCard />
        </div>
      </div>
    }

    return <Section
      className="py-16 md:py-24">
      <Container>
        <Content breakpoint={breakpoint} />
      </Container>
    </Section>
  }
)

const AboutUs = () => {
  const Background = () => {
    return <>
      <div
        className="absolute bottom-0 left-0 w-full h-3/4 bg-hs-blue-600 z-0" />
      <img
        src={AboutUsBg}
        alt="HackSpace"
        className="absolute top-0 left-0 w-full h-auto" />
    </>
  }

  const Decoration = () => {
    return <AboutUsDecoration
      className="absolute top-0 right-0 transform translate-x-1/4 -translate-y-1/4" />
  }

  const Content = () => {
    const breakpoint = useBreakpoint()

    return <div
      className="grid grid-cols-1 md:grid-cols-2 gap-16 mx-auto w-full lg:w-3/4">
      <div
        className="bg-white md:col-span-2 p-4 sm:p-8 md:p-16 lg:p-24">
        <img
          src={HackSpaceBee}
          alt="HackSpace"
          className="w-20 mx-auto block mb-4" />
        <Typography
          size={breakpoint?.mdAndUp ? 'eight' : 'six'}
          className="font-black text-center text-hs-blue-600">
          Sobre Nosotros
        </Typography>
        <Typography
          size="four"
          className="text-center text-hs-blue-600">
          Promovemos y difundimos la <span className="text-hs-yellow-300">Cultura del Hacer</span>
          entre los profesionales del país mediante la organización diversos
          programas y actividades. Nuestro objetivo principal es generar
          entornos para la creación por medio de la tecnología basados en el
          crecimiento en comunidad y el auto-aprendizaje.
        </Typography>
      </div>

      <div
        className="md:col-span-2">
        <Typography
          size="five"
          className="w-full lg:w-3/4 mx-auto font-bold text-center text-white mb-8">
          El valor y conocimiento de nuestra comunidad lo ofrecemos en cursos y talleres cortos.
        </Typography>
        <div
          className="h-2 w-16 bg-hs-yellow-300 mx-auto" />
      </div>

      <div
        className="md:col-span-2">
        <Typography
          size="four"
          className="text-center text-white mb-4">
          De esta manera reforzamos la confianza para la creación de nuestros miembres. Así, vamos supliendo la falta de espacios libres para la creación y potenciamos el sistema educativo tradicional.
        </Typography>

        <Typography
          size="four"
          className="text-center text-white">
          Además, nuestro alumni tiene acceso a mejores oportunidades trabajo, investigación y especialización
        </Typography>
      </div>

      <div
        className="p-8 md:p-16 bg-white flex flex-col justify-center">
        <Typography
          size="four"
          className="text-hs-blue-700">
          <span className="text-hs-yellow-300 font-bold">Boost</span>{' '}
          es nuestra apuesta por acelerar el proceso de aprendizaje para
          profesionales que no tienen el tiempo o la disponibilidad para
          involucrarse en nuestros programas de comunidad.
        </Typography>
      </div>

      <div
        className="p-8 md:p-16 bg-white flex flex-col justify-center">
        <Typography
          size="four"
          className="text-hs-blue-700">
          <span className="text-hs-yellow-300 font-bold">Outsourcing</span>{' '}
          son los servicios con los llegamos a impactar en empresas gracias a
          los profesionales más experimentados de nuestra comunidad.
        </Typography>
      </div>
    </div>
  }

  return <Section
    className="pb-20 pt-64 md:pt-96">
    <Decoration />
    <Background />
    <Container>
      <Content />
    </Container>
  </Section>
}

const ContactUs = () => {
  const Decoration = () => {
    return <div
      className="md:col-span-5">
      <img
        src={Section4Decoration}
        alt="home"
        className="block ml-auto w-full"
        style={{
          maxWidth: "480px"
        }} />
    </div>
  }

  const Text = () => {
    const breakpoint = useBreakpoint()

    return <div
      className="md:col-span-7 flex flex-col justify-center items-center">
      <div
        className="lg:ml-auto xl:mr-auto lg:w-4/5">
        <Typography
          size={breakpoint?.lgAndUp ? "nine" : (breakpoint?.mdAndUp ? "eight" : "seven")}
          className="mb-4 font-black text-hs-blue-600">
          Contáctanos
        </Typography>
        <Typography
          size={"three"}
          className="text-hs-blue-600 mb-8">
          Escríbenos y te responderemos lo antes posible
        </Typography>
        <div
          style={{
            maxWidth: "400px",
          }}>
          <ContactForm formId="contact-form-on-contact-section" />
        </div>
      </div>
    </div>
  }

  const Content = () => {
    const breakpoint = useBreakpoint()

    return <div
      className="grid grid-cols-1 md:grid-cols-12 gap-12 sm:gap-8">
      <Text />
      {
        breakpoint?.mdAndUp
          ? <Decoration />
          : null
      }
    </div>
  }

  return <Section
    className="pt-16 pb-32 md:pt-24 md:pb-36">
    <Container>
      <Content />
    </Container>
  </Section>
}

const Page = ({ ...attrs }) => {

  const communityRef = createRef()
  const whereToStartRef = createRef()

  return (
    <Main {...attrs}>
      <PageBg
        className="w-full md:max-w-3/4 h-auto absolute top-0 right-0" />
      <Intro />
      <Navigation />
      <Community
        ref={communityRef} />
      <Courses />
      <Outsourcing
        ref={whereToStartRef} />
      <AboutUs />
      <ContactUs />
    </Main >
  );
}

export default Page;
