import { useLayoutEffect, useEffect, useState } from 'react'

const getClientWidth = () => {
    // SSR
    if (document === undefined) {
        return 0
    }

    return Math.max(
        document.documentElement.clientWidth,
        window.innerWidth || 0
    )
}

const getClientHeight = () => {
    // SSR
    if (document === undefined) {
        return 0
    }

    return Math.max(
        document.documentElement.clientHeight,
        window.innerHeight || 0
    )
}

const thresholds = {
    xs: 0,
    sm: 640,
    md: 768,
    lg: 1024,
    xl: 1280,
    xxl: 1536
}

const breakpointData = {
    xsAndDown: false,
    xs: false,
    xsAndUp: false,
    xsOnly: false,

    smAndDown: false,
    sm: false,
    smAndUp: false,
    smOnly: false,

    mdAndDown: false,
    md: false,
    mdAndUp: false,
    mdOnly: false,

    lgAndDown: false,
    lg: false,
    lgAndUp: false,
    lgOnly: false,

    xlAndDown: false,
    xl: false,
    xlAndUp: false,
    xlOnly: false,

    xxlAndDown: false,
    xxl: false,
    xxlAndUp: false,
    xxlOnly: false,

    name: '',

    height: 0,
    width: 0
}

export const useBreakpoint = () => {
    const [breakpoints, setBreakpoints] = useState(breakpointData)
    const useIsomorphicLayoutEffect = typeof window !== 'undefined' ? useLayoutEffect : useEffect

    useIsomorphicLayoutEffect(() => {
        function updateBreakpoints () {
            const width = getClientWidth()
            const height = getClientHeight()
            const xs = width < thresholds.sm
            const sm = width < thresholds.md && !xs
            const md = width < thresholds.lg && !(sm || xs)
            const lg = width < (thresholds.xl) && !(md || sm || xs)
            const xl = width < (thresholds.xxl) && !(lg || md || sm || xs)
            const xxl = width >= (thresholds.xxl)

            let name = 'xxl'

            if (xs) {
                name = 'xs'
            }
            if (sm) {
                name = 'sm'
            }
            if (md) {
                name = 'md'
            }
            if (lg) {
                name = 'lg'
            }
            if (xl) {
                name = 'xl'
            }

            setBreakpoints({
                xsAndDown: xs,
                xs: xs,
                xsAndUp: (xs || sm || md || lg || xl || xxl),
                xsOnly: xs,

                smAndDown: (xs || sm) && !(md || lg || xl || xxl),
                sm: sm,
                smAndUp: (sm || md || lg || xl || xxl),
                smOnly: sm,

                mdAndDown: (xs || sm || md) && !(lg || xl || xxl),
                md: md,
                mdAndUp: !(xs || sm) && (md || lg || xl || xxl),
                mdOnly: md,

                lgAndDown: (xs || sm || md || lg) && !(xl || xxl),
                lg: lg,
                lgAndUp: !(xs || sm || md) && (lg || xl || xxl),
                lgOnly: lg,

                xlAndDown: (xs || sm || md || lg || xl) && !(xxl),
                xl: xl,
                xlAndUp: !(xs || sm || md || lg) && (xl || xxl),
                xlOnly: xl,

                xxlAndDown: (xs || sm || md || lg || xl || xxl),
                xxl: xxl,
                xxlAndUp: xxl,
                xxlOnly: xxl,

                name: name,

                width: width,
                height: height
            })
        }

        window.addEventListener('resize', updateBreakpoints)
        updateBreakpoints()

        return () => window.removeEventListener('resize', updateBreakpoints)
    }, [])

    return breakpoints
}
