import Container from "./Container"
import Typography from "./Typography"
import { useBreakpoint } from "../hooks/breakpoint"

import HackSpaceLogo from "../assets/hackspace.png"
import { ReactComponent as YoutubeLogo } from "../assets/youtube.svg"
import { ReactComponent as FacebookLogo } from "../assets/facebook.svg"
import { ReactComponent as TwitterLogo } from "../assets/twitter.svg"
import { ReactComponent as LinkedinLogo } from "../assets/linkedin.svg"
import { ReactComponent as InstagramLogo } from "../assets/instagram.svg"

const FooterSection = ({ children }) => {
  return <div className="flex flex-row items-center">
    {children}
  </div>
}

const Logo = () => {
  return (
    <FooterSection>
      <img src={HackSpaceLogo} alt="HackSpace" className="h-14 w-auto" />
    </FooterSection>
  )
}

const Description = () => {
  return (
    <FooterSection>
      <Typography
        size="one"
        className="text-gray-900 w-full md:w-2/3 lg:w-1/2">
        Promovemos y difundimos la Cultura del Hacer entre los
        profesionales del país mediante la organización diversos
        programas y actividades.
      </Typography>
    </FooterSection>
  )
}

const SocialNetworks = () => {
  return (
    <FooterSection>
      <div className="bg-gray-50 inline-block rounded-md p-2 h-10">
        <a
          href="http://instagram.com/hackspace"
          rel="noopener noreferrer"
          className="inline-block mr-4 h-6">
          <InstagramLogo
            className="block h-6 w-6" />
        </a>
        <a
          href="http://twitter.com/hackspace"
          rel="noopener noreferrer"
          className="inline-block mr-4 h-6">
          <TwitterLogo
            className="block h-6 w-6" />
        </a>
        <a
          href="http://linkedin.com/hackspace"
          rel="noopener noreferrer"
          className="inline-block mr-4 h-6">
          <LinkedinLogo
            className="block h-6 w-6" />
        </a>
        <a
          href="http://youtube.com/hackspace"
          rel="noopener noreferrer"
          className="inline-block mr-4 h-6">
          <YoutubeLogo
            className="block h-6 w-6" />
        </a>
        <a
          href="http://facebook.com/hackspace"
          rel="noopener noreferrer"
          className="inline-block h-6">
          <FacebookLogo
            className="block h-6 w-6" />
        </a>
      </div>
    </FooterSection>
  )
}

const Copyright = () => {
  return (
    <FooterSection>
      <Typography
        size="one"
        className="text-gray-900 w-full">
        © 2020 Todos los rerechos reservados. Desarrollado por HackSpace Perú
      </Typography>
    </FooterSection>
  )
}

const Footer = () => {
  const breakpoint = useBreakpoint()

  return (
    <footer className="bg-gray-200 py-8 md:py-16">
      <Container>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <Logo />
          <Description />
          {
            breakpoint?.mdAndUp ? (
              <>
                <Copyright />
                <SocialNetworks />
              </>
            ) : (
              <>
                <SocialNetworks />
                <Copyright />
              </>
            )
          }
        </div>
      </Container>
    </footer>
  );
}

export default Footer;
