import classNames from 'classnames'

const Main = ({ children, ...attrs }) => {
    const { className } = attrs
    attrs.className = classNames(
        className,
        [
            "flex-grow"
        ]
    )

    return (
        <main {...attrs}>
            {children}
        </main>
    )
};

export default Main;
