import { useEffect } from 'react';
import { Routes, Route, useLocation } from "react-router-dom";

import Home from './Home'
import NotFound from './NotFound'
import ThankYou from './ThankYou'

import NavBar from '../components/NavBar'
import Footer from '../components/Footer'

import '../styles/app.css';

const App = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div className="min-h-screen w-full flex flex-col relative overflow-hidden">
      <NavBar />
      <Routes>
        <Route path="/" element={<Home className="mt-16" />} />
        <Route path="/thank-you" element={<ThankYou className="mt-16" />} />
        <Route path="/*" element={<NotFound className="mt-16" />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
