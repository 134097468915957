import classNames from "classnames";
import { Link } from "react-router-dom";

import Main from '../components/Main';
import Section from '../components/Section';
import Container from "../components/Container";
import Typography from "../components/Typography";
import { useBreakpoint } from "../hooks/breakpoint";

import { ReactComponent as ChevronRightIcon } from "../assets/chevron-right.svg"
import { ReactComponent as DataPoints } from "../assets/data-points.svg"

const ThankYou = ({ ...attrs }) => {
  const breakpoint = useBreakpoint()

  attrs.className = classNames(
    attrs.className,
    [
      "flex",
      "flex-col"
    ]
  )

  return (
    <Main {...attrs}>
      <Section
        className="flex flex-col flex-grow items-center justify-center bg-hs-yellow-300 w-full min-h-full overflow-hidden">
        <Container>
          <Typography
            size={breakpoint?.mdAndUp ? "seven" : "four"}
            monospaced
            className="text-center font-light text-gray-700">
            ¡Gracias por contactarnos!
          </Typography>
          <Typography
            size={breakpoint?.mdAndUp ? "seven" : "four"}
            monospaced
            className="text-center font-light text-gray-700 mb-12">
            Nos pondremos en contacto contigo en breve
          </Typography>
          <Link
            to="/"
            className="block bg-white p-4 rounded-3xl w-full mx-auto"
            style={{
              maxWidth: '400px'
            }}>
            <Typography
              size={breakpoint?.mdAndUp ? "four" : "three"}
              className="text-center">
              Volver al bootcamp
              <ChevronRightIcon
                className="inline-block ml-2" />
            </Typography>
          </Link>
        </Container>
        <DataPoints
          className="absolute right-0 bottom-0 transform -translate-x-2 translate-y-72" />
      </Section>
    </Main>
  )
}

export default ThankYou;
