import PropTypes from 'prop-types'
import classNames from 'classnames'

import styles from '../styles/typography.module.scss'

const Typography = ({ children, tag, size, clamp, monospaced, ...attrs }) => {
  const { className } = attrs
  attrs.className = classNames(className, {
    [styles.typography]: true,
    [styles[size]]: size,
    [styles.truncate]: clamp === 1,
    [styles.monospaced]: monospaced
  })

  if (clamp === 1) {
    const { style = {} } = attrs
    style.minWidth = 0
    attrs.style = style
  }

  if (clamp > 1) {
    const { style = {} } = attrs
    style.display = '-webkit-box'
    style.overflow = 'hidden'
    style.textPverflow = 'ellipsis'
    style.WebkitBoxOrient = 'vertical'
    style.WebkitLineClamp = clamp
    attrs.style = style
  }

  const TagName = tag
  return (
    <TagName {...attrs}>
      {children}
    </TagName>
  )
}

Typography.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  size: PropTypes.string,
  tag: PropTypes.string,
  clamp: PropTypes.number,
  monospaced: PropTypes.bool
}

Typography.defaultProps = {
  tag: 'div',
  size: null,
  monospaced: false,
}

export default Typography
