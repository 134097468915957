import { forwardRef } from 'react';
import classNames from 'classnames';

const Section = forwardRef(
  ({ children, ...attrs }, ref) => {

    attrs.className = classNames(
      attrs.className,
      [
        'relative'
      ]
    )

    return (
      <section ref={ref} {...attrs}>
        {children}
      </section >
    )
  }

)

export default Section;
