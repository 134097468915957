import classNames from "classnames";
import { Link } from "react-router-dom";

import Main from '../components/Main';
import Section from '../components/Section';
import Container from "../components/Container";
import Typography from "../components/Typography";
import { useBreakpoint } from "../hooks/breakpoint";

import Section1Decoration from "../assets/notfound-section1-decoration.png"

const NotFound = ({ ...attrs }) => {
  const breakpoint = useBreakpoint()

  attrs.className = classNames(
    attrs.className,
    [
      "flex",
      "flex-col"
    ]
  )

  return (
    <Main {...attrs}>
      <Section
        className="flex flex-col flex-grow items-center justify-center bg-hs-yellow-300 w-full h-full">
        <Container>
          <img
            src={Section1Decoration}
            alt="404 Not Found"
            className="block w-full h-auto mx-auto mb-20"
            style={{
              maxWidth: "480px",
            }} />
          <Typography
            size={breakpoint?.mdAndUp ? "six" : "four"}
            className="text-center font-bold text-hs-blue-600">
            Esta no es la página que estás buscando
          </Typography>
          <Typography
            size="three"
            className="text-center text-hs-blue-600 mb-12">
            Lo sentimos, a menos que realmente esperara encontrar nuestro
            mensaje 404, la página que estaba buscando se ha movido o
            eliminado.
          </Typography>
          <div
            className="-mx-4 flex items-center justify-center flex-wrap">
            <div
              className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 flex-none px-4 my-2">
              <Link
                to="/data-analytics"
                className="block px-4 py-2 cursor-pointer rounded-3xl w-full mx-auto border-2 border-hs-blue-600 w-full">
                <Typography
                  size="three"
                  className="text-center text-hs-blue-600">
                  Volver al bootcamp
                </Typography>
              </Link>
            </div>

            <div
              className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 flex-none px-4 my-2">
              <Link
                to="/"
                className="block m-1 px-4 py-2 cursor-pointer rounded-3xl w-full mx-auto border-2 border-hs-blue-600 w-full">
                <Typography
                  size="three"
                  className="text-center text-hs-blue-600">
                  Ir a la pagina de inicio
                </Typography>
              </Link>
            </div>

            <div
              className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 flex-none px-4 my-2">
              <Link
                to="/"
                className="block m-1 px-4 py-2 cursor-pointer rounded-3xl w-full mx-auto border-2 border-hs-blue-600 w-full">
                <Typography
                  size="three"
                  className="text-center text-hs-blue-600">
                  Conversar con alguien
                </Typography>
              </Link>
            </div>
          </div>
        </Container>
      </Section>
    </Main>
  )
}

export default NotFound;
