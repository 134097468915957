import React, { useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import classNames from 'classnames'

const Modal = ({ children, open, onOpen, onClose }) => {
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    setIsOpen(open)
  }, [open])

  useEffect(() => {
    const handleEscape = (event) => {
      if (event.keyCode === 27) {
        setIsOpen(false)
      }
    }

    if (isOpen) {
      document.addEventListener('keydown', handleEscape, false)
      document.querySelector('html').classList.add('overflow-hidden')

      if (onOpen) {
        onClose()
      }
    } else {
      document.querySelector('html').classList.remove('overflow-hidden')
      if (onClose) {
        onClose()
      }
    }

    return () => {
      document.removeEventListener('keydown', handleEscape, false)
    }
  }, [onOpen, onClose, isOpen])

  const getRootElement = () => {
    let rootElement = document.querySelector('.js-modal-root')

    if (!rootElement) {
      rootElement = document.createElement('div')
      rootElement.classList.add('js-modal-root')
      document.body.appendChild(rootElement)
    }

    return rootElement
  }

  return createPortal(
    <div
      className={
        classNames([
          'fixed',
          'top-0',
          'left-0',
          'w-screen',
          'max-w-screen',
          'h-screen',
          'max-h-screen',
          'bg-black',
          'bg-opacity-25',
          'flex',
          'items-center',
          'justify-center',
          'overflow-auto',
          'z-50',
          isOpen ? '' : 'hidden'
        ])
      }
      onClick={(e) => { e.stopPropagation(); setIsOpen(false) }}>
      {children}
    </div>,
    getRootElement()
  )
}

export default React.memo(Modal)
