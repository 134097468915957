const submitEvent = (event, properties) => {
  if (window?.dataLayer) {
    window?.dataLayer.push(
      {
        event,
        ...properties
      }
    )
  }
}

const TagManager = {
  submitEvent,
}

export default TagManager
