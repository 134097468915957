import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import App from './pages';
import Firebase from './components/Firebase';
import reportWebVitals from './plugins/reportWebVitals';

import './styles/index.css';

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter basename="/">
      <Firebase.Context.Provider value={new Firebase()}>
        <App />
      </Firebase.Context.Provider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
