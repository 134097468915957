import { useState, useEffect, useCallback } from "react"
import classNames from "classnames"
import { Link } from "react-router-dom";

import Modal from "./Modal"
import Container from "./Container"
import Typography from "./Typography"
import ContactForm from "./Forms/Contact"

import { useBreakpoint } from "../hooks/breakpoint"

import HackSpaceLogo from "../assets/hackspace.png"
import { ReactComponent as MenuIcon } from "../assets/menu.svg"
import { ReactComponent as CloseIcon } from "../assets/close.svg"

const NavbarItem = ({ children, ...attrs }) => {
  attrs.className = classNames(
    attrs.className,
    [
      "flex",
      "flex-col",
      "justify-center",
      "py-3"
    ]
  )
  return (
    <div {...attrs}>
      {children}
    </div>
  )
}

const NavbarLogo = ({ ...attrs }) => {
  return <NavbarItem {...attrs}>
    <Link
      to="/"
      className="inline-block">
      <img src={HackSpaceLogo} alt="HackSpace" className="h-10 w-auto" />
    </Link>
  </NavbarItem>
}

const NavbarToggler = ({ isOpen, ...attrs }) => {
  return (
    <NavbarItem {...attrs}>
      {
        isOpen ? <CloseIcon className="h-10 w-10" /> : <MenuIcon className="h-10 w-10" />
      }
    </NavbarItem >
  )
}

/*
const NavbarLink = ({ children, to, ...attrs }) => {
  let resolved = useResolvedPath(to);
  let match = useMatch({ path: resolved.pathname, end: true });

  attrs.className = classNames(
    attrs.className,
    {
      "pb-1": match,
      "border-b-2": match,
      "border-hs-yellow-200": match,
    }
  )

  return <Link
    to={to}
    {...attrs}>
    {children}
  </Link>
}
*/

const Navbar = () => {
  const breakpoint = useBreakpoint()
  const [isOpen, setIsOpen] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)

  useEffect(() => {
    setIsOpen(false)
  }, [breakpoint])

  const onModalClose = useCallback(
    () => {
      setIsModalOpen(false)
    },
    []
  )

  return <>
    <Modal
      open={isModalOpen}
      onClose={onModalClose}>
      <div
        className="w-screen max-w-screen h-screen max-h-screen p-4 flex items-center justify-center"
        onClose={onModalClose}>
        <div
          className="bg-white p-4 md:py-8 md:px-16 w-full rounded-3xl max-h-full overflow-auto pointer-events-auto"
          style={{
            maxWidth: '480px'
          }}
          onClick={(e) => e.stopPropagation()}>
          <ContactForm formId="contact-form-on-navbar" />
        </div>
      </div>
    </Modal>
    <header className={`bg-white w-full fixed top-0 z-40 ${isOpen ? 'h-screen flex flex-col' : 'h-16'} `}>
      <nav
        className="h-16 w-full">
        <Container
          className="flex flex-row justify-center md:justify-start">
          <NavbarLogo
            className="md:mr-8"
            onClick={() => setIsOpen(false)} />
          {
            breakpoint?.smAndDown
              ? <NavbarToggler
                className="fixed right-4"
                isOpen={isOpen}
                onClick={() => setIsOpen(!isOpen)} />
              : <>
                <NavbarItem
                  className="mx-2">
                  <div
                    className="relative">
                    <Typography
                      size="three"
                      className="font-bold text-center text-hs-blue-600">
                      Data Science
                    </Typography>
                    <Typography
                      size="one"
                      className="px-2 rounded-2xl bg-hs-yellow-300 text-center absolute right-0 top-0 transform translate-x-1/2 -translate-y-3/4 text-hs-blue-600">
                      Nuevo
                    </Typography>
                  </div>
                </NavbarItem>
                <div
                  className="flex-grow" />
                <NavbarItem
                  className="mx-2">
                  <div
                    className="bg-hs-yellow-300 py-2 px-4 rounded-3xl cursor-pointer"
                    onClick={() => setIsModalOpen(true)}>
                    <Typography
                      size="two"
                      className="text-center">
                      ¡Contáctanos!
                    </Typography>
                  </div>
                </NavbarItem>
              </>
          }
        </Container>
      </nav>
      {
        isOpen
          ? <div
            className="overflow-auto h-full">
            <Container
              className="flex flex-col py-4 h-full">
              <Typography
                size="five"
                className="font-bold text-center">
                <Link
                  to="/data-analytics"
                  onClick={() => setIsOpen(false)}>
                  Soy estudiante
                </Link>
              </Typography>
              <div
                className="w-full border-b-2 my-4" />
              <Typography
                size="five"
                className="font-bold text-center">
                <Link
                  to="/partners"
                  onClick={() => setIsOpen(false)}>
                  Sé nuestro partner
                </Link>
              </Typography>
              <div
                className="flex-grow" />
              <div
                className="mb-4">
                <Link
                  to="/partners"
                  className="block border-2 border-black p-3 rounded-3xl cursor-pointer"
                  onClick={() => setIsOpen(false)}>
                  <Typography
                    size="three"
                    className="text-center">
                    Quiero ser partner
                  </Typography>
                </Link>
              </div>
              <div>
                <div
                  className="bg-hs-yellow-300 p-3 rounded-3xl cursor-pointer"
                  onClick={() => { setIsOpen(false); setIsModalOpen(true); }}>
                  <Typography
                    size="three"
                    className="text-center">
                    ¡Me interesa!
                  </Typography>
                </div>
              </div>
            </Container>
          </div>
          : null
      }
    </header>
  </>
}

export default Navbar;
