import classNames from 'classnames'

const Container = ({ children, ...attrs }) => {
  const { className } = attrs
  attrs.className = classNames(
    className,
    [
      "relative",
      "container",
      "mx-auto",
      "px-4"
    ]
  )

  return <div {...attrs}>
    {children}
  </div >
};

export default Container;
