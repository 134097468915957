import Typography from "../Typography"

import { ReactComponent as CheckGreen } from "../../assets/check-green.svg"

const ThankYou = () => {
  return <>
    <CheckGreen
      className="block h-16 w-16" />
    <Typography
      size="three"
      className="text-hs-blue-200">
      Muchas gracias.<br />
      Nos contactaremos contigo en breve.
    </Typography>
  </>
}

export default ThankYou
