import React, { useEffect, useState } from "react"
import PhoneInput from "react-phone-number-input"
import 'react-phone-number-input/style.css'

import ThankYou from "./ThankYou"
import Typography from "../Typography"
import { withFirebase } from "../Firebase"
import TagManager from "../TagManager"
import { ReactComponent as ChevronRightIcon } from "../../assets/chevron-right.svg"

const Form = ({ firebase, formId = null }) => {
  console.log(formId)
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [subject, setSubject] = useState("")
  const [legalAcceptance, setLegalAcceptance] = useState(false)
  const [phone, setPhone] = useState("")
  const [message, setMessage] = useState("")
  const [errors, setErrors] = useState("")
  const [isSent, setIsSent] = useState(() => {
    if (window?.sessionStorage) {
      const storedValue = sessionStorage.getItem("Partners.isSent")
      const value = Boolean(parseInt(storedValue))
      return value
    }
    return false
  })

  const reset = () => {
    setPhone("")
    setFirstName("")
    setLastName("")
    setEmail("")
    setSubject("")
    setLegalAcceptance(false)
    setPhone("")
    setMessage("")
    setErrors({})
  }

  const validate = () => {
    const errors = {}

    if (!firstName) errors.firstName = "Este campo es requerido"
    if (!lastName) errors.lastName = "Este campo es requerido"
    if (!email) errors.email = "Este campo es requerido"
    if (!phone) errors.phone = "Este campo es requerido"
    if (!subject) errors.subject = "Este campo es requerido"
    if (!legalAcceptance) errors.legalAcceptance = "Debes aceptar el acuerdo legal"
    if (!message) errors.message = "Este campo es requerido"

    return { isValid: Object.keys(errors).length === 0, errors }
  }

  const handleSubmit = async (event) => {
    event.stopPropagation();
    event.preventDefault();
    setErrors({})

    const { isValid, errors } = validate()

    if (!isValid) {
      setErrors(errors)
    } else {
      try {
        const timestamp = (new Date()).toISOString()
        await firebase.saveContact(
          {
            data: {
              timestamp,
              firstName,
              lastName,
              email,
              phone,
              subject,
              message,
              legalAcceptance,
            }
          }
        );

        reset()
        setIsSent(true)

        TagManager.submitEvent(
          'hs.submitForm',
          {
            'hs.formName': 'contact',
            'hs.data': {
              timestamp,
              firstName,
              lastName,
              email,
              phone,
              subject,
              message,
              legalAcceptance,
            },
            'gtm.element': event.target,
          }
        )
      } catch (e) {
        console.log(e)
        setErrors({ nonFieldError: e.message })
      }
    }
  }

  useEffect(
    () => {
      sessionStorage.setItem("Contact.isSent", isSent ? 1 : 0)

      return () => {
        sessionStorage.removeItem("Contact.isSent")
      }
    },
    [isSent]
  )

  return <form
    id={formId}
    className="w-full relative"
    style={{
      minHeight: '500px'
    }}
    onSubmit={handleSubmit}>
    {
      isSent
        ? <div
          className="flex flex-col space-y-4">
          <ThankYou />
        </div>
        : <>
          <div
            className="mb-4">
            <Typography
              tag="label"
              size="three"
              className="block text-hs-blue-100 mb-1">
              Nombres
            </Typography>
            <input
              name="firstname"
              type="text"
              value={firstName}
              onChange={(event) => setFirstName(event.target.value)}
              className="block bg-gray-200 outline-none w-full h-10 py-2 px-4 rounded-md" />
            {
              errors?.firstName
                ? <Typography
                  size="one"
                  className="text-hs-red-400 mt-2">
                  {errors?.firstName}
                </Typography>
                : null
            }
          </div>
          <div
            className="mb-4">
            <Typography
              tag="label"
              size="three"
              className="block text-hs-blue-100 mb-1">
              Apellidos
            </Typography>
            <input
              name="lastname"
              type="text"
              value={lastName}
              onChange={(event) => setLastName(event.target.value)}
              className="block bg-gray-200 outline-none w-full h-10 py-2 px-4 rounded-md" />
            {
              errors?.lastName
                ? <Typography
                  size="one"
                  className="text-hs-red-400 mt-2">
                  {errors?.lastName}
                </Typography>
                : null
            }
          </div>
          <div
            className="mb-4">
            <Typography
              tag="label"
              size="three"
              className="block text-hs-blue-100 mb-1">
              Correo electrónico
            </Typography>
            <input
              name="email"
              type="email"
              value={email}
              onChange={(event) => setEmail(event.target.value)}
              className="block bg-gray-200 outline-none w-full h-10 py-2 px-4 rounded-md" />
            {
              errors?.email
                ? <Typography
                  size="one"
                  className="text-hs-red-400 mt-2">
                  {errors?.email}
                </Typography>
                : null
            }
          </div>
          <div
            className="mb-4">
            <Typography
              tag="label"
              size="three"
              className="block text-hs-blue-100 mb-1">
              Celular
            </Typography>
            <PhoneInput
              international
              defaultCountry="PE"
              countryCallingCodeEditable={false}
              value={phone}
              onChange={(value) => setPhone(value)}
              className="block bg-gray-200 outline-none w-full h-10 py-2 px-4 round-md flex"
              type="text"
              numberInputProps={
                {
                  className: "outline-none bg-transparent",
                  style: {
                    border: '0',
                    outline: '0!important'
                  }
                }
              } />
            {
              errors?.phone
                ? <Typography
                  size="one"
                  className="text-hs-red-400 mt-2">
                  {errors?.phone}
                </Typography>
                : null
            }
          </div>
          <div
            className="mb-4">
            <Typography
              tag="label"
              size="three"
              className="block text-hs-blue-100 mb-1">
              Asunto
            </Typography>
            <input
              name="subject"
              type="text"
              value={subject}
              onChange={(event) => setSubject(event.target.value)}
              className="block bg-gray-200 outline-none w-full h-10 py-2 px-4 rounded-md" />
            {
              errors?.subject
                ? <Typography
                  size="one"
                  className="text-hs-red-400 mt-2">
                  {errors?.subject}
                </Typography>
                : null
            }
          </div>
          <div
            className="mb-4">
            <Typography
              tag="label"
              size="three"
              className="block text-hs-blue-100 mb-1">
              Mensaje
            </Typography>
            <textarea
              name="textarea"
              type="textarea"
              value={message}
              onChange={(event) => setMessage(event.target.value)}
              className="block bg-gray-200 outline-none resize w-full h-full p-4 rounded-md" />
            {
              errors?.message
                ? <Typography
                  size="one"
                  className="text-hs-red-400 mt-2">
                  {errors?.message}
                </Typography>
                : null
            }
          </div>
          <div
            className="mb-4">
            <Typography
              tag="label"
              size="two"
              className="block text-hs-blue-100 mb-1">
              <input
                name="legalAcceptance"
                type="checkbox"
                checked={legalAcceptance}
                onChange={(event) => setLegalAcceptance(event.target.checked)}
                className="inline-block bg-gray-200 outline-none rounded-md mr-2" />
              <span>
                Aceptas expresamente dar estos datos para que te proporcionemos
                información sobre nuestros servicios de formación. Tus datos
                los gestionaremos según la legislación y pueden ser cedidos a
                terceros homologados. Detallamos nuestra{' '}
                <a
                  href="#terms-and-conditions-link"
                  rel="noopener noreferrer"
                  target="_blank"
                  className="text-hs-blue-400">
                  politicas de privacidad
                </a>
              </span>
            </Typography>
            {
              errors?.legalAcceptance
                ? <Typography
                  size="one"
                  className="text-hs-red-400 mt-2">
                  {errors?.legalAcceptance}
                </Typography>
                : null
            }
          </div>
          <button
            type="submit"
            className="bg-hs-yellow-300 py-3 px-6 inline-block rounded-3xl w-full cursor-pointer">
            <Typography
              size="three"
              className="text-center">
              Enviar
              <ChevronRightIcon
                className="inline-block h-4 w-4 ml-1" />
            </Typography>
          </button>
        </>
    }
  </form>
}

export default withFirebase(Form)
